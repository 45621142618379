import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate, formatNumber } from '../../../../../utils/formatacoes';
import ImportacaoProcessosService from '../../../../../Services/Importacao/ImportacaoProcessosService';
import { toast } from 'react-toastify';

const NumerariosTable = ({ numerarios }) => {
  const [rows, setRows] = useState([]);

  const handleRelatorioClick = async (id) => {
    toast.info('Gerando o relatório. Por favor, aguarde...');

    try {
      const data = await ImportacaoProcessosService.relNumerario(id);
      window.open(URL.createObjectURL(data));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const columns = [
    {
      id: 'data_cadastro',
      name: 'Data Cadastro',
      selector: (row) => formatDate(row.data_cadastro, false) ?? '-',
    },
    {
      id: 'nome',
      name: 'Descrição',
      selector: (row) => row.descricao ?? '-',
    },
    {
      id: 'data_vencimento',
      name: 'Data Vencimento',
      selector: (row) => formatDate(row.data_vencimento, false) ?? '-',
    },
    {
      id: 'data_recebimento',
      name: 'Data Pagamento',
      selector: (row) => formatDate(row.data_recebimento, false) ?? '-',
    },
    {
      id: 'valor',
      name: 'Valor R$',
      right: true,
      selector: (row) => (row.situacao === 3 ? formatNumber(row.valor_liquido) : formatNumber(row.valor_real) ?? '-'),
    },
    {
      id: 'situacao',
      name: 'Situação',
      selector: (row) => row.situacao ?? '-',
    },
    {
      name: 'Ações',
      id: 'actions',
      center: true,
      compact: true,
      width: '10vh',
      cell: (row) => (
        <i
          className='icofont icofont-papers'
          onClick={() => handleRelatorioClick(row.id)}
          style={{ fontSize: 20, cursor: 'pointer' }}
          title='Gerar relatório do numerário'
        />
      ),
    },
  ];

  useEffect(() => {
    if (!numerarios) return;

    setRows(numerarios);
  }, [numerarios]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default NumerariosTable;
